html {
    overflow-x: hidden !important;
    /* Prevent horizontal scrolling */
    /* padding: 0;
    margin: 0; */
}

body {
    line-height: 1.5;
    font-family: 'Noto Kufi Arabic', sans-serif;
    background-color: #fff;
    overflow-x: hidden !important;
    /* padding: 0;
    margin: 0; */
    font-variant-ligatures: common-ligatures;
    direction: rtl;
    unicode-bidi: normal;
}

/* theme 2 */

.Them2 .mainLanding .card{
background-color: transparent;
}

.Them2 .mainLanding .card .card-title .t1,
.Them2 .mainLanding .card .card-title .t2{
    color: #fff;
}


/* theme 2 */


/* start logo Carousel */
.uderDev {
    overflow: hidden; /* Hide overflow content */
    padding: 20px 0;
    background:  #f6e0ae;
    position: relative;
    white-space: nowrap; /* Prevent text wrapping */
    
}

.uderDev:before,
.uderDev:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.uderDev:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #f6e0ae);
}

.uderDev:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #f6e0ae);
}
.uderDev-slide {
    display: inline-block; /* Align items in a single line */
    animation: scroll 15s infinite linear; /* Smooth horizontal scrolling */
}

.uderDev-slide h4 {
    display: inline-block; /* Ensure horizontal alignment */
    margin: 0 40px; /* Space between texts */
    font-size: 20px;
    color: #967DA5;
    direction: rtl; /* Right-to-left for Arabic */
}

/* @keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
} */
/* End logo Carousel */



#quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px auto;
    width: 70%;
}

#quiz-container h3 {
    font-size: 1.5rem;
    color: gray;
    margin-bottom: 40px;
}

/* Circle container styling */
#circle-container {
    display: flex;
    /* justify-content:; Distribute evenly */
    align-items: center;
    width: 40%;
    max-width: 600px;
    margin-top: 40px;
    position: relative;
}

/* Circle styles */
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease;
}

/* Add hover effect */
.circle:hover {
    transform: scale(1.1);
}

/* Label container styling */
.label-container {
    display: flex;
    justify-content: space-between;
    /* Align below corresponding circles */
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-top: 10px;
    text-align: center;
}

.label-container span {
    font-size: 1rem;
    font-weight: bold;
}

/* Individual label styles */
.label-green {
    color: #b1ded2;
    /* Green */
}

.label-purple {
    color: #967DA5;
    /* Purple */
}


/* Question text */
.personality-type {
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;
}

.personality-type.selected {
    background-color: #f6e0ae;
    /* Highlight with yellow */
    border-radius: 10px;
}

#personalityDescription {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.progress-bar {
    transition: width 0.5s ease;
    border-radius: 5px;
}

.results-container {
    display: flex;
    flex-direction: row;
    /* Default: side-by-side */
    flex-wrap: wrap;
}

.description-container {
    margin-top: 20px;
}

/* Media Query for Smaller Screens */


/* Style for the description container */
.description-container {
    background-color: #f9f9f9;
    /* Light gray background */
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Heading Style */
.description-container h3 {
    color: #9e85b5;
    /* Brand purple */
    font-weight: bold;
}

/* Text Style */
.description-container p {
    color: #555;
    /* Complementary text color */
    background-color: #f9f9f9;
    /* Light gray for contrast */
    padding: 10px;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.5;
}

/* Highlight Style */
.description-container strong {
    color: #9e85b5;
    /* Brand purple for emphasis */
    font-size: 1.1rem;
    display: block;
    margin-top: 10px;
}

.logText {
    font-size: 1rem;
    color: #05003899;
    margin-top: 3rem;
    font-variant-ligatures: common-ligatures;
    direction: rtl;
    unicode-bidi: normal;
}

/* top 
right 
bottom 
left   */

/* 
top
right and left 
bottom padding 
*/


/* Start mainLanding */
.mainLanding .card {
    border: none;
}

.mainLanding .card .card-title .t1 {
    color: #BFDFD0;
    font-size: 6rem;
    line-height: 73px;
    font-weight: 400;
    display: block;
    text-wrap: nowrap;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}


.mainLanding .card .card-title .t2 {
    color: #64BDA1;
    font-size: 3.9rem;
    line-height: 200px;
    font-weight: 900;
    display: block;
    text-wrap: nowrap;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;

}


.mainLanding .card .col-lg-6 img{
    max-width: 120%;
}
/* End mainLanding */

/* Start bk-yellow */
.bk-yellow {
    background-color: #FFF5DC;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}

.bk-yellow h1 {
    font-size: 50px;
    color: #7A4B92;
    font-weight: 700;
    line-height: 55px;
}

.bk-yellow p {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    line-height: 30px;
}

/* End bk-yellow */

/* start we-offer */

.we-offer h1 {
    color: #754E8F;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1px;

}

.we-offer .card {
    backdrop-filter: blur(30px);
    border: 1px solid;
    border-image-source: linear-gradient(276.86deg, rgba(255, 255, 255, 0.4) -14.84%, rgba(255, 254, 212, 0.4) 102.64%);
    border-radius: 25px;
    background: linear-gradient(94.62deg, rgba(255, 255, 255, 0.4) -4.38%, rgba(249, 245, 208, 0.4) 165.35%);
}

.we-offer .card .card-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: -0.02em;
    text-align: right;
}

.we-offer .card .card-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: -0.02em;
    text-align: right;
}

.we-offer .card:hover {
    /* border: 1px solid #967DA5; */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* end we-offer */

/* start vision */

.vision-card {
    margin: 80px auto;
    max-width: 858px;
    max-height: 350px;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0px 20px 25px -5px #0000001A, 0px 0px 10px 0px #00000012;
    padding: 48px 48px 24px 48px;
    gap: 8px;
    border-radius: 25px;
    /* overflow: hidden; */
}

.vision-card h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    color: #754E8F;

}

.vision-card p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    color: #0F172A;

}

.vision-icon {
    position: absolute;
    top: 50%;
    right: -440px;
    transform: translateY(-50%);
    max-width: 506px;
    max-height: 448px;
    /* transition: all 0.3s ease-in-out; */
}

/* .vision-icon:hover {
    transform: translateY(-50%) scale(1.1);
} */

.vision-link {
    color: #967DA5;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-align: left !important;
}

.vision-link:hover {
    text-decoration: underline;
}

/* end vision */

/* Start values */

.values {
    margin-top: 40px;
}

.values h1{
    padding: 10px;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1px;
    color: #754E8F;
}
.value-box {
    background-color: #fff;
    border-radius: 15px;
    /* padding: 7px 136px; */
    gap: 10px;
    box-shadow: 1px 3px 19.4px 0px #00000026;
    font-size: 30px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -1px;
    text-align: center;
    color: #754E8F;
    /* Adjust the purple color */
    transition: all 0.3s ease-in-out;
}

.value-box:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

.value-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -1px;
    text-align: center;
    color: #754E8F;
    padding: 12px 11px;
    /* background: linear-gradient(92.72deg, rgba(208, 187, 228, 0.1) -3.34%, rgba(246, 231, 255, 0.1) 102.96%); */
    background: linear-gradient(135deg, #f8f6fc, #eae2f8);

    border: 1px solid;
    border-image-source: linear-gradient(92.39deg, rgba(150, 125, 165, 0.5) -18.43%, rgba(249, 239, 255, 0.5) 97.7%);
    backdrop-filter: blur(20px);
    border-radius: 15px;
    /* transition: all 0.4s ease-in-out; */

}
/* .value-description:hover {
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    background: linear-gradient(135deg, #d4c3f9, #eae2f8);
    color: #432f8c;
} */

/* End values */

/* start logo Carousel */

.logos {
    overflow: hidden;
    padding: 60px 0;
    background: white;
    white-space: nowrap;
    position: relative;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: scroll 15s infinite linear;
}

.logos-slide img {
    height: 50px;
    margin: 0 40px;
    display: inline-block;
    opacity: 1;
    transition: opacity 0.5s ease;
}

@keyframes scroll {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(50%); /* Scroll halfway */
    }
}

/* End logo Carousel */


/* Start mission */

.mission {
    margin-top: 150px;
    margin-bottom: 100px;
    position: relative;

}

.mission h3 {
    color: #754E8F;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1px;
}

.mission .card {
    padding: 23px 34.55px 44px 24px;
    gap: 29px;
    border: none;
}

.mission .card-title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #050038;

}

.mission .card-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: #000;
}

.mission .highlight-card {
    background: linear-gradient(93.08deg, rgba(237, 229, 244, 0.5) -37.3%, rgba(255, 255, 255, 0.5) 103.02%);
    border: 1px solid;
    border-image-source: linear-gradient(267.98deg, #D1C7DA -14.83%, #E9D8FA 120.73%);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    z-index: 1;
    overflow: hidden;
}

.mission .mission-logo {
    position: absolute;
    top: 0;
    left: -20px;
    z-index: -1;
    max-width: 331px;
    max-height: 258px;


}

/* End mission */



/* Start Blog */
/* .container-fluid.blog-section{
    background-color: #DDEEE4;
    overflow-x: hidden;
} */

.blog-section {
    background-color: #DDEEE4;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100%;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}

.blog-section .container {}



/* Start header titles  */
.blog_header {
    margin-top: 150px;
    margin-bottom: 60px;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}

.blog_header .t1 {
    margin-right: -8px;
    font-size: 4rem;
    font-weight: 400;
    line-height: 70.4px;
    letter-spacing: -2px;
    /* text-align: right; */
    color: #967DA5;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}

.blog_header .t2 {
    display: block;
    margin-top: 40px;
    margin-left: -73px;
    font-size: 8rem;
    font-weight: 700;
    line-height: 70.4px;
    letter-spacing: -2px;
    text-align: right;
    color: #754E8F;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}

/* End header titles  */


/* Start main Post */
.mainPost {
    border: none;
    cursor: pointer;
    border-radius: 25px;
}

.R1 .mainPost img {
    height: 480px;
    border-radius: 25px;
}

.R2 .mainPost img {
    height: 550px;
    border-radius: 25px;

}

.R3 .mainPost img {
    height: 350px;
    border-radius: 25px;
}

.mainPost .card-img-overlay {
    color: #fff;
    border-radius: 25px;
    padding: 50px 40px;
}

.mainPost .card-img-overlay h1 {
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 2.5rem;
    font-variant-ligatures: common-ligatures;
    unicode-bidi: normal;
}

.mainPost .card-img-overlay {
    background-color: #3112435E;
    color: #fff;
    border-radius: 25px;
    padding: 50px 40px;
}

.restOfBlog {
    height: 48px;
    padding: 10px 20px 14px 19px;
    border-radius: 50px;
    transition: all 0.2s ease;
    text-decoration: none;
}


/* End main Post */

/* End Blog */

/* Start Features */
.Features .card {
    border: none;
}

/* End Features */

@media (max-width: 1399px){
    .mainLanding .card .card-title .t1 {
        font-size: 5.6rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 3.5rem;
    }
}

@media (max-width: 1200px) {
    .vision-icon {
        right: -380px;
        /* Adjust position for smaller screens */
        max-width: 450px;
    }
}

@media (max-width: 1199px) {
    .mainLanding .card .col-lg-6 img {
        max-width: 140%;
    }

    .mainLanding .card .card-title .t1 {
        font-size: 5rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 3rem;
    }

    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 5.6rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 3.5rem;
    }
}

@media (max-width:1180px){
    .mainLanding .card .card-title .t1 {
        font-size: 4rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 2rem;
    }
}

@media (max-width:1199px)and (min-width:992px) {
    .mission .card {
        padding: 0;
    }
}

/* @media (max-width: 1199px) and (min-width: 992px),
(max-width: 650px) and (min-width: 530px) {

    .mainLanding .card .card-title .t1 {
        font-size: 5.5rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 3.4rem;
    }

} */

@media (max-width:820px){
    .mainLanding .card .card-title .t2 {
        font-size: 3rem;
    }
}

@media (max-width: 991px) {
    .vision-card {
        padding: 40px 24px 20px 24px;
        max-height: auto;
        margin: 40px auto 280px;

    }

    .mission .mission-logo {
        top: 55%;
        left: -90px;
    }

    .vision-icon {
        position: static;
        /* Move the image below the content */
        transform: translateY(-10%);
        display: block;
        /* max-height: auto; */
        max-height: 350px;
    }

    .bk-yellow h1 {
        font-size: 35px;
    }

    .position-relative,
    .position-absolute,
    .top-50,
    .start-50,
    .translate-middle {
        position: static !important;
        /* Reset position */
        top: auto !important;
        left: auto !important;
        transform: none !important;
    }

    .mainLanding .card .card-title .t1 {
        font-size: 6rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 3.9rem;
    }

    .mainLanding .card .col-lg-6 img {
        max-width: 120%;
    }


    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 5rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 3rem;
    }

}

@media (max-width: 768px) {
    .uderDev:before,
.uderDev:after {
    width: 150px;
}
    .vision-card {
        /* padding: 32px 16px; */
        max-height: 400px;
    }

    .mission .mission-logo {
        top: 76%;
    }

    .vision-link {
        text-align: left !important;
    }

    .we-offer h1 {
        font-size: 40px;
    }

    .bk-yellow p {
        font-size: 15px;
    }

    .bk-yellow h1 {
        font-size: 30px;
    }

    .results-container {
        flex-direction: column;
        /* Stack vertically */
        align-items: center;
    }

    .description-container {
        margin-top: 20px;
        /* Add spacing */
        width: 100%;
        /* Full width */
    }

    .midTitle h1 {
        font-size: 7vw;
    }

    .midTitle::before {
        font-size: 2vw;
        left: 65%;
        top: -10px;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #quiz-container {
        margin: 0;
        width: 100%;
    }

    .label-container span {
        font-size: .7rem;
    }


    .navbar .useraccount:hover,
    .navbar .useraccount:hover .fa-angle-down,
    .navbar .useraccount:focus,
    .navbar .useraccount:focus .fa-angle-down,
    .navbar .useraccount:active,
    .navbar .useraccount:active .fa-angle-down {
        border: 2px solid #967DA5;
        color: #967DA5;
    }

    .blog_header .t1 {
        font-size: 3rem;
    }

    .blog_header .t2 {
        font-size: 6rem;
        margin-top: 20px;
    }

    .R1 .mainPost img,
    .R2 .mainPost img,
    .R3 .mainPost img {
        height: 250px;
    }


    .mainPost .card-img-overlay h1 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 40px;

    }

    .mainLanding .card .card-title .t1 {
        font-size: 5.3rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 3.5rem;
    }

    .mainLanding .card .col-lg-6 img {
        max-width: 110%;
    }


    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 4.6rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 2.5rem;
    }
}

@media (max-width: 670px) {
    .bk-yellow h1 {
        font-size: 25px;
        line-height: 45px;
    }

    .bk-yellow p {
        font-size: 12px;
    }

    .mainLanding .card .card-title .t1 {
        font-size: 4.5rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 3rem;
    }

    .mainLanding .card .col-lg-6 img {
        max-width: 100%;
    }

    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 4rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .uderDev:before,
    .uderDev:after {
        width: 50px;
    }
    .story .card-body {
        font-size: 12px;
        line-height: 30px;
    }
    .bk-yellow h1 {
        font-size: 20px;
    }

    .midTitle h1 {
        font-size: 10vw;
    }

    .blog_header .t1 {
        font-size: 2.5rem;
        line-height: 1rem;
        margin-right: 5px;
    }

    .blog_header .t2 {
        font-size: 4rem;
        /* margin-top: 3px; */

    }

    #quiz-container h3 {
        font-size: 1.2rem;
    }

    .navbar .useraccount {
        margin-top: 10px;
    }

    .mainPost .card-img-overlay h1 {
        font-size: 1rem;
    }

    .mainLanding .card .card-title .t1 {
        font-size: 4rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 2.8rem;
    }

    .vision-icon {
        max-width: 350px;
    }

    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 3rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 1.5rem;
        line-height: 70px;

    }

}

@media (max-width: 530px) {
    .vision-card p {
        font-size: 15px;
    }

    .mainLanding .card .card-title .t1 {
        font-size: 3.5rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 2.5rem;
    }
}

@media (max-width: 499px) {
    .we-offer h1 {
        font-size: 35px;
    }

    .we-offer .card .card-title {
        font-size: 20px;
    }

    .we-offer .card .card-text {
        font-size: 12px;
    }

    .vision-icon {
        max-width: 300px;
    }
}

@media (max-width: 479px) {
    .bk-yellow h1 {
        font-size: 18px;
    }

}

@media (max-width: 475px) {
    .mainLanding .card .card-title .t1 {
        font-size: 3rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 2rem;
        line-height: 100px;
    }

    .story .card-body{
        padding: 16px;
    }

    .story .card img{
        width: 80%;
    }
}

@media (max-width: 450px) {
    .story .card-body{
        line-height: 25px;
    }
    .we-offer h1 {
        font-size: 30px;
    }

    .we-offer .card .card-title {
        font-size: 15px;
    }

    .we-offer .card .card-text {
        font-size: 10px;
    }

    .vision-icon {
        max-width: 250px;
        transform: translate(20%, -20%);
    }

    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 2.6rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 1.5rem;

    }
}

@media (max-width: 399px) {
    .we-offer h1 {
        font-size: 25px;
    }

    .we-offer .card .card-title {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
    }

    .we-offer .card .card-text {
        text-align: center;
    }

    .mission h3 {
        font-size: 36px;
        line-height: 42px;
    }


    .Them2 .mainLanding .card .card-title .t1 {
        font-size: 2rem;
    }

    .Them2 .mainLanding .card .card-title .t2 {
        font-size: 1rem;

    }
}

@media (max-width: 385px) {
    .story .card-body{
        font-size: 10px;
        line-height: 15px;
    }
    .story .card img{
        width: 85%;
    }
    .value-box{
        font-size: 25px;
    }
    .values h1{
        font-size: 40px;
    }
    .value-description{
        font-size: 15px;
    }
    .vision-card {
        max-height: 500px;
    }

    .vision-icon {
        max-width: 200px;
        transform: translate(20%, -2%);
    }

    .mainLanding .card .card-title .t1 {
        font-size: 3rem;
        line-height: 43px;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 1.5rem;
        line-height: 63px;
    }

    .blog_header .t1 {
        font-size: 2rem;
    }

    .blog_header .t2 {
        font-size: 3rem;
    }

    #quiz-container h3 {
        font-size: 1rem;
    }

    .circle-container {
        width: 100%;
    }
}

/* @media (min-width: 820px) and (max-width: 834px) and (orientation: portrait),
(min-width: 1180px) and (max-width: 1194px) and (orientation: landscape) {
    .mainLanding .card .card-title .t1 {
        font-size: 4.5rem;
    }

    .mainLanding .card .card-title .t2 {
        font-size: 2.4rem;
    }
} */
