/* @font-face {
    font-family: 'Black';
    src: url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Black-1.woff2) format('woff2'),
        url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Black-1.woff) format('woff'),
        url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Black-1.ttf) format('truetype');
    font-display: swap;

}

@font-face {
    font-family: 'Bold';
    src: url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Bold-1.woff2) format('woff2'),
        url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Bold-1.woff) format('woff'),
        url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Bold-1.ttf);
    font-display: swap;

}

@font-face {
    font-family: 'Regular';
    src: url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Regular-1.woff2) format('woff2'),
        url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Regular-1.woff) format('woff'),
        url(../assets/Fonts/Ar/alfont_com_AlFont_com_TheMixArab-Regular-1.ttf);
    font-display: swap;

} */

@font-face {
    font-family: 'Museo900Italic';
    src: url(../assets/Fonts/En/Museo-900Italic.ttf);
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');

/* theme 2 */
.Them2{
    background-image: url('../assets/images/backgrwond.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.Them2 .navbar .useraccount ,
.Them2 .navbar .useraccount .fa-angle-down,
.Them2 .navbar .navbar-nav .nav-item .nav-link {
    color: #fff;
    border-color: #fff;
}

.Them2  .navbar .useraccount:hover,
.Them2  .navbar .useraccount:hover .fa-angle-down,
.Them2  .navbar .useraccount.active,
.Them2  .navbar .useraccount.active .fa-angle-down  {
    border: 2px solid #F1DDA6;
    color: #F1DDA6;
}

/* nav items */
.Them2 .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #F1DDA6;
}

.Them2 .navbar .navbar-nav .nav-item .nav-link.active {
    color: #F1DDA6;
}

.Them2 .mainLanding .tryNuhieh{
    background: #F1DDA6;
    color: #053C76;
    font-weight: 700;
}
/* theme 2 */


/* start nav bar */
.navbar {
    background-color: transparent !important;
    font-size: 1rem;
    line-height: 24px;
}

/* user account  */
.navbar .useraccount {
    width: 159.27px;
    height: 48px;
    padding: 13px 0px;
    border-radius: 9999px;
    border: 1px solid #000;
    transition: all 0.2s ease;
    text-align: center;
}


.navbar .useraccount .fa-angle-down {
    border: 1px solid #000;
    border-radius: 50%;
    padding: 1px;
    margin-left: 25px;
    margin-right: -25px;
    font-size: 10px;
}

.navbar .useraccount:hover,
.navbar .useraccount:hover .fa-angle-down,
.navbar .useraccount.active,
.navbar .useraccount.active .fa-angle-down  {
    border: 2px solid #967DA5;
    color: #967DA5;
}


/* try Nuhieh  */
.navbar .tryNuhieh,
.mainLanding .tryNuhieh,
.Features .tryNuhieh,
.retake,
.explaining #startQuiz ,
.goQize,
.StudentEvaluation .createClass,
.StudentEvaluation #saveChanges,
.StudentEvaluation .backToClass,
.navbar #MyClassRoom{
    width: 153.92px;
    height: 48px;
    padding: 10px 20px 14px 19px;
    border-radius: 50px;
    background: #967DA5;
    color: #fff;
    font-family: 'Noto Kufi Arabic', sans-serif;
    transition: all 0.2s ease;
    text-decoration: none;
    border: 0;
}

.explaining #startQuiz ,
.StudentEvaluation .createClass,
.StudentEvaluation .backToClass{
    width: auto;
}

.navbar #MyClassRoom{
    width: 190px;
}
.StudentEvaluation .backToClass{
    padding: 0px 20px;
font-size: 16px;
font-weight: 700;
line-height: 24px;

}
.navbar .tryNuhieh img {
    width: 34px;
    height: 23px;
}


.navbar .tryNuhieh:hover,
.mainLanding .tryNuhieh:hover,
.Features .tryNuhieh:hover,
.retake:hover,
.explaining #startQuiz:hover,
.goQize:hover,
.createClass:hover,
.StudentEvaluation #saveChanges:hover,
.StudentEvaluation .backToClass:hover,
.navbar #MyClassRoom:hover {
    background-color: #754E8F;
    color: #fff;
}

/* nav items */
.navbar .navbar-nav .nav-item .nav-link:hover {
    color: #967DA5;
}

.navbar .navbar-nav .nav-item .nav-link.active {
    color: #754E8F;
}

/* End nav bar */



/* Start footer */
.emaildiv .NhuiehEmail {
    font-size: 20px;
    /* font-weight: 800; */
    line-height: 25.2px;
    text-align: left;
    text-decoration: none;
    color: #00000066;
    font-family: 'Museo900Italic';
}

.emaildiv .NhuiehEmail:hover {
    color: #967DA5;
}

.emaildiv {
    border: 1px solid #00000020;
    border-left: none;
    border-right: none;
}

.footer .footerTitle {
    /* font-family: Plus Jakarta Sans; */
    font-family: 'Museo900Italic';
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 3px;
    text-align: right !important;
    color: #00000066;

}

.footer .footerLinks a {
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right !important;
    color: #000;
    text-decoration: none;
    margin: 20px ;
}

.footer .footerLinks a:hover {
    color: #967DA5;
}

.footer .footerImg {
    border-left: 1px solid #00000020;
}

.footer .footerLinks a.social-media{
    font-size: 25px;
    margin: 0px 5px;
}
/* End footer */



/* Overlay styles */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensures it’s above all other content */
}

/* Container for logo and spinner */
.spinner-container {
    text-align: center;
}

/* Loading logo style */
.loading-logo {
    width: 8rem;
    height: 8rem;
    background-color: transparent !important;
}


/* Target iPad Air (10.9") and iPad Pro (11") */

